export function MarketingNewsLetter(): JSX.Element {
  return (
    <div className="z-1 relative">
      <div className="lg-down:py-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="bg-primary-700 rounded-lg p-6 md:p-12 lg:p-16 xl:flex xl:items-center">
          <div className="xl:w-0 xl:flex-1">
            <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
              Want to learn more?
            </h2>
            <p className="mt-3 max-w-3xl text-lg leading-6 text-indigo-200">
              Sign up to learn more about pricing and working with Snap
            </p>
          </div>
          <div className="-mb-14 -ml-10 overflow-hidden sm:w-full sm:max-w-md md:-mb-20 md:-ml-12 lg:-my-20">
            <div className="flex justify-end">
              <iframe
                title="newsletter"
                width="100%"
                height="305"
                src="https://33e7282f.sibforms.com/serve/MUIEALUc0L9Fda9MBPy5-iIOk3mn8CWqUuWlaJ3Tw5PLU98VI-yMlNTiKwC6x-lLKyznhABesujyImMBpQBVROYoNrLQDbBU4OjSbADYDmkBrVl1i5nuEoQxqq8G8I3Nt1UUnmY5g2w3oBDwzLo-1r6fmiD5vvsXcc_3MQ_WF-G9U9rYa7pZAoi6cPOnuco-Yvn_TWzapqocYxKt"
                frameBorder="0"
                scrolling="no"
                className="z-1 relative"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
