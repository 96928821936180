import { ReactNode } from 'react'
import { tw, TwUiSize } from 'packages/lib/tw'

export const buttonPresets = {
  primary:
    'bg-primary-500 hover:bg-primary-700 focus:ring-primary-600 border-transparent text-white',
  secondary:
    'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-offset-0 focus:ring-1 focus:border-primary-600 focus:ring-primary-600',
}

type JSXButton = Omit<JSX.IntrinsicElements['button'], 'ref'>

export interface ButtonIconProps {
  position?: 'left' | 'right'
  icon?: ReactNode
}

type ButtonPresets = keyof typeof buttonPresets

export interface ButtonProps extends JSXButton {
  size?: TwUiSize
  preset?: ButtonPresets
  leftIcon?: ButtonIconProps
  rightIcon?: ButtonIconProps
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ButtonIcon = tw<ButtonIconProps & { 'data-testid': string }>(
  ({ icon, 'data-testid': id }) => <span data-testid={id}>{icon}</span>,
)`
  ${({ position = 'left' }) =>
    ({
      left: 'mr-2',
      right: 'ml-2',
    }[position])}
`

export const Button = tw<ButtonProps>(({ children, leftIcon, rightIcon, ...props }) => (
  // eslint-disable-next-line react/button-has-type
  <button {...props}>
    {leftIcon && <ButtonIcon position="left" data-testid="Button__IconLeft" {...leftIcon} />}
    {children}
    {rightIcon && <ButtonIcon position="right" data-testid="Button__IconRight" {...rightIcon} />}
  </button>
))`
  disabled:cursor-not-allowed disabled:bg-gray-300
  focus:outline-none focus:ring-2 focus:ring-offset-2
  font-medium rounded-md shadow-sm border inline-flex items-center w-full justify-center

  ${({ preset = 'primary' }) => buttonPresets[preset]}

  ${({ size = 'base' }) =>
    ({
      xs: 'px-2.5 py-1.5 leading-4 text-xs',
      sm: 'px-3 py-2 leading-4 text-sm',
      base: 'px-4 py-2 text-sm',
      lg: 'px-4 py-2 text-base',
      xl: 'px-6 py-3 text-base',
    }[size])}
`
