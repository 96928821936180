import { ReactNode } from 'react'
import { tw } from 'packages/lib/tw'
import { Container } from '../Container'

interface MarketingProductDisplayProps extends Omit<JSX.IntrinsicElements['div'], 'ref'> {
  /** Default is left */
  position?: 'left' | 'right'
  product?: ReactNode
}

const ContentWrapper = tw<MarketingProductDisplayProps>(Container)`
  flex flex-col justify-center max-w-xl
  sm:px-6
  lg:py-20 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2

  ${({ position = 'left' }) => ({ left: 'lg:col-start-2', right: 'lg:col-start-1' }[position])}
`

const ContentContainer = tw.div`flex flex-col justify-center align-middle mt-6 prose md-down:prose-sm`

const ProductWrapper = tw.div<MarketingProductDisplayProps>`
  flex flex-grow relative align-middle justify-center mt-12 sm:mt-16 lg:mt-0 lg-down:overflow-hidden
  lg-down:shadow-xl

  ${({ position = 'left' }) =>
    ({
      left: 'lg:col-start-1 lg-down:mr-8 lg:-ml-48',
      right: 'lg:col-start-2 lg-down:ml-8 lg:-mr-48',
    }[position])}
`

const ProductContainer = tw.div<MarketingProductDisplayProps>`
  flex flex-col justify-center align-middle flex-shrink lg:px-0 lg:w-full

  ${({ position = 'left' }) =>
    ({
      left: 'md:-ml-16 sm-down:-ml-8',
      right: 'md:-mr-16 sm-down:-mr-8',
    }[position])}
`

const ProductBox = tw.div<MarketingProductDisplayProps>`
  flex rounded-xl shadow-xl overflow-hidden
`

export const MarketingProductDisplay = tw<MarketingProductDisplayProps>(
  ({ position, product, children, ...props }) => (
    <section {...props}>
      <ContentWrapper position={position}>
        <ContentContainer>{children}</ContentContainer>
        {/* <p className="mt-10">
            <a
              href="#"
              className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Get started
            </a>
          </p> */}
      </ContentWrapper>

      <ProductWrapper position={position}>
        <ProductContainer position={position}>
          <ProductBox>{product}</ProductBox>
        </ProductContainer>
      </ProductWrapper>
    </section>
  ),
)`lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 my-10 md-down:my-5`
