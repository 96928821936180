// libs
import { tw } from 'lib/tw'
import Image from 'next/image'
import React, { Suspense, lazy, ReactNode } from 'react'
// layout
import { PublicLayout } from 'layouts'
// icons
import NHDLogoSVG from 'public/nhd-logo.svg'
import TaxLogoSVG from 'public/tax-logo.svg'
import FloodLogoSVG from 'public/flood-logo.svg'
// components
import { Button } from 'ui/Button'
import {
  MarketingHero,
  MarketingNewsLetter,
  MarketingSimpleCard,
  MarketingProductDisplay,
} from 'ui/Marketing'

const TrustBoxWidget = lazy(() => import('ui/TrustBoxWidget'))

// views
const Link = tw('a', { target: '_blank' })`-mb-2 text-center`

const NHDExampleLink = tw(Link, { href: '/examples/snap-nhd-report-example.pdf' })``

const FloodExampleLink = tw(Link, { href: '/examples/snap-flood-report-example.pdf' })``

const TaxExampleLink = tw(Link, { href: '/examples/snap-tax-api-call-example.json' })``

function Home() {
  return (
    <main className="overflow-hidden">
      <MarketingHero className="lg:mb-20" />

      <MarketingNewsLetter />

      <MarketingProductDisplay
        product={
          <NHDExampleLink>
            <Image
              src="/examples/snap-nhd-example.jpg"
              height={640}
              width={921}
              alt="snap-nhd-example"
            />
          </NHDExampleLink>
        }
        className="z-1 relative"
        position="right"
      >
        <NHDLogoSVG />

        <p>
          Automated natural hazard disclosure reports delivered in a Snap. Reports contain State
          data, Local data, licensed C.A.R. Forms, and other tailored disclosures when applicable.
        </p>

        <NHDExampleLink>
          <Button>View Example</Button>
        </NHDExampleLink>
      </MarketingProductDisplay>

      <MarketingProductDisplay
        product={
          <FloodExampleLink>
            <Image
              src="/examples/snap-flood-example.jpg"
              height={640}
              width={921}
              alt="snap-flood-example"
            />
          </FloodExampleLink>
        }
        className="z-1 relative"
      >
        <FloodLogoSVG />

        <p>
          Automated flood certificates delivered at industry best prices. Services include basic
          flood zone determinations, life of loan monitoring, portfolio life of loan transfer, and
          HMDA Information.
        </p>

        <FloodExampleLink>
          <Button>View Example</Button>
        </FloodExampleLink>
      </MarketingProductDisplay>

      <MarketingProductDisplay
        product={
          <TaxExampleLink>
            <Image
              src="/examples/snap-tax-example.jpg"
              height={640}
              width={921}
              alt="snap-tax-example"
            />
          </TaxExampleLink>
        }
        className="z-1 relative"
        position="right"
      >
        <TaxLogoSVG />

        <p>
          Detailed property tax record and address verification products delivered via customizable
          API endpoints.
        </p>

        <TaxExampleLink>
          <Button>View Example</Button>
        </TaxExampleLink>
      </MarketingProductDisplay>

      <MarketingSimpleCard />

      <Suspense fallback={<div>Trustpilot...</div>}>
        <TrustBoxWidget />
      </Suspense>
    </main>
  )
}

Home.getLayout = ({ children }: { children: ReactNode }): JSX.Element => (
  <PublicLayout title="Snap - Automated workflow solutions for the Proptech industry">
    {children}
  </PublicLayout>
)

export default Home
