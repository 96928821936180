import Image from 'next/image'

export function MarketingSimpleCard() {
  return (
    <section className="lg-down:mt-12 z-1 bg-primary-500 relative my-20 pt-12 sm:pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            We care about compliance
          </h2>
        </div>
      </div>
      <div className="mt-10 bg-[#F7F5F2] pb-12 sm:pb-16">
        <div className="relative">
          <div className="bg-primary-500 absolute inset-0 h-1/2" />
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-4xl">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                    <Image
                      src="/compliance/soc2-drata.png"
                      width={150}
                      height={150}
                      alt="Data SOC2"
                    />
                  </dd>
                </div>
                <div className="flex flex-col border-y border-gray-100 p-6 text-center sm:border-0 sm:border-x">
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                    <Image
                      src="/compliance/aicpa-soc.png"
                      width={150}
                      height={150}
                      alt="AICPA SOC2"
                    />
                  </dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                    <Image
                      src="/compliance/nfa-logo.jpg"
                      width={150}
                      height={150}
                      alt="National Flood Association"
                    />
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
