// libs
import { tw } from 'packages/lib/tw'
// types
import { ReactNode } from 'react'

// views
const ButtonGroupWrapper = tw.div`relative inline-flex shadow-sm`

const Button = tw.button`
  focus:ring-primary-600 focus:border-primary-600 relative inline-flex items-center border
  border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 first:rounded-l-md
  last:rounded-r-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1
`

const ButtonLabel = tw.span<{ $hasIcon: boolean }>`${({ $hasIcon }) =>
  $hasIcon ? 'ml-2.5' : 'ml-0'}`

// types
type GroupButton = {
  label?: string
  icon?: ReactNode
  clickHandler(): void
}

interface ButtonGroupProps {
  buttons: GroupButton[]
}

export function ButtonGroup({ buttons }: ButtonGroupProps): JSX.Element {
  return (
    <ButtonGroupWrapper>
      {buttons.map((item, i) => (
        <Button type="button" key={item.label ?? i} onClick={item.clickHandler}>
          {item.icon}
          {item.label && <ButtonLabel $hasIcon={!!item.icon}>{item.label}</ButtonLabel>}
        </Button>
      ))}
    </ButtonGroupWrapper>
  )
}
